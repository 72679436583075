import { css } from "goober";

export const Contents = () => css`
  display: grid;
  gap: var(--sp-2);
`;

export const MatchStats = () => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--sp-3);

  .stats {
    display: flex;
    align-items: baseline;

    span {
      display: block;
      width: 9ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:not(:first-child) {
        color: var(--shade2);
      }
    }
  }

  .summoners-runes,
  .items {
    display: grid;
    gap: var(--sp-0_5);

    img:not(.rune),
    .slot {
      border-radius: var(--br-sm);
      background: var(--shade4-15);
    }
  }
  .summoners-runes {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: column;

    .keystone {
      scale: 1.2;
    }
  }
  .items {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  &[data-is-swarm="true"] {
    .items {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

export const Teams = () => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: var(--sp-1);

  .team {
    display: grid;
    gap: var(--sp-0_5);
  }
  .player {
    display: flex;
    align-items: center;
    gap: var(--sp-1);
    color: var(--shade2);

    &.is-local-player {
      color: var(--shade0);
    }
  }
  .player-name {
    max-width: 7ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Augments = () => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: var(--sp-1);

  .augment {
    background: var(--shade5-15);
    border-radius: var(--br);
  }
  img {
    opacity: 0;

    &[src] {
      opacity: 1;
    }
  }
`;

import React from "react";
import { useTranslation } from "react-i18next";

import { formatDuration } from "@/app/util.mjs";
import { ArenaAugmentImage } from "@/game-lol/components/ArenaAugmentImage.jsx";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import {
  Augments,
  Contents,
  MatchStats,
  Teams,
} from "@/game-lol/components/MatchTile.style.jsx";
import Badges from "@/game-lol/components/PostMatchBadges.jsx";
import {
  MAX_GAME_TIME_IN_MINUTES,
  SCORABLE_QUEUE_TYPES,
} from "@/game-lol/constants/constants.mjs";
import { CHAMPION_ID_MAP } from "@/game-lol/constants/swarm-constants.mjs";
import { kdaColor } from "@/game-lol/utils/kda-color.mjs";
import { findPlayer, isSamePlayer } from "@/game-lol/utils/match-utils.mjs";
import PlayerScores from "@/game-lol/utils/player-scores.mjs";
import Static from "@/game-lol/utils/static.mjs";
import QueueSymbol, {
  QUEUE_SYMBOL_TO_OBJECT,
} from "@/game-lol/utils/symbol-queue.mjs";
import {
  durationToMinutes,
  isARAMQueue,
  isArenaQueue,
  isRankedQueue,
  isRemakeGame,
  isSwarmQueue,
  // isRoleQueue,
  isWinningTeam,
} from "@/game-lol/utils/util.mjs";
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
import { classNames } from "@/util/class-names.mjs";
import globals from "@/util/global-whitelist.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";

const MatchTileTabsEnum = {
  Scoreboard: "scoreboard",
  Stats: "stats",
};

const TABS = {
  [MatchTileTabsEnum.Scoreboard]: () =>
    import("@/game-lol/components/MatchScoreboard.jsx"),
  [MatchTileTabsEnum.Stats]: () =>
    import("@/game-lol/components/MatchStats.jsx"),
};

function MatchTileContainer({
  matchId,
  match = {},
  region,
  name,
  matchLinkPrefix,
  profile,
  lp,
}) {
  const { t } = useTranslation();

  const {
    queueId,
    gameMode,
    gameDuration,
    participants,
    // teams,
    gameCreation,
  } = match;

  // Useless game
  if (!participants?.length) return null;

  const teams = (match.teams || []).map((team) => {
    return {
      ...team,
      roster: (participants || []).filter((p) => p.teamId === team.teamId),
    };
  });

  const queue = QueueSymbol(queueId || gameMode);

  const isARAM = isARAMQueue(queue);
  const isArena = isArenaQueue(queue);
  const isSwarm = isSwarmQueue(queue);
  const isRemake = !isSwarm && isRemakeGame(gameDuration);
  const _isRanked = isRankedQueue(queue);
  const isScorable = SCORABLE_QUEUE_TYPES.includes(queue);
  const durationAsMinutes = durationToMinutes(gameDuration);

  const localPlayer = findPlayer(profile, match);

  // Cant find the player, unfortunately this results in just a grey
  // box in the place of the match. There should be a better way to handle this
  if (!localPlayer) return null;

  const playersRankings = isScorable ? PlayerScores(match) : null;

  const {
    teamId,
    subteamPlacement,
    win: wonGame,
    // teamPosition: role,
    kills,
    deaths,
    assists,
    totalMinionsKilled,
    neutralMinionsKilled,
    totalDamageDealtToChampions,
    totalDamageDealt,
    // goldSpent,
    summoner1Id,
    summoner2Id,
    perks,
    item0,
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
  } = localPlayer;

  const championId = isSwarm
    ? CHAMPION_ID_MAP[localPlayer.championId]
    : localPlayer.championId;

  const userRanking =
    playersRankings &&
    playersRankings.findIndex(
      (p) => p.championId === championId && p.teamId === teamId,
    ) + 1;

  const userPoints =
    userRanking &&
    playersRankings.find(
      (p) => p.championId === championId && p.teamId === teamId,
    );

  const winningTeamId = teams.find((team) => isWinningTeam(team))?.teamId;
  const bestWinner = playersRankings?.find((p) => p.teamId === winningTeamId);
  const isMVP = isArena
    ? subteamPlacement === 1
    : teamId === bestWinner?.teamId && championId === bestWinner?.championId;
  const win = isArena
    ? subteamPlacement <= 4
    : isScorable
      ? !!(userPoints && userPoints.victory)
      : wonGame;

  // const icon = isARAM ? "aram" : ROLE_SYMBOL_TO_STR[role]?.gql;
  // const _RoleIcon = isRoleQueue(queue) && getHextechRoleIcon(icon);
  // const _statsData = {
  //   participants,
  //   ownData: localPlayer,
  //   gameCreation,
  //   gameDuration,
  // };

  // Player stats
  const kda = (kills + assists) / (deaths || 1);
  const csTotal = totalMinionsKilled + neutralMinionsKilled;
  const csPerMin = csTotal / durationAsMinutes;
  const dmgTotal = totalDamageDealtToChampions;
  const dmgPerMin = dmgTotal / durationAsMinutes;
  // const dmgPerGold = dmgTotal / goldSpent;

  const runes = [
    perks?.styles?.[0]?.selections?.[0]?.perk, // Keystone
    perks?.styles?.[1]?.style, // Secondary tree
  ].filter(Boolean);
  const inventory = isSwarm
    ? [item0, item1, item2, item3, item4]
    : [item0, item1, item2, item6, item3, item4, item5];

  // Construct the title strings
  const titles = [
    {
      text: isRemake
        ? ["lol:results.remake", "Remake"]
        : isArena
          ? [
              "common:stats.placement",
              "{{placement, ordinal}}",
              {
                placement: subteamPlacement,
              },
            ]
          : win
            ? ["common:victory", "Victory"]
            : ["common:defeat", "Defeat"],
      bold: true,
      color: isRemake
        ? "var(--shade2)"
        : isMVP
          ? "var(--gold)"
          : win
            ? "var(--turq)"
            : "var(--red)",
    },
  ];

  // LP (0 is a valid value)
  if (typeof lp === "number") {
    titles.push({
      text: [
        "lol:leaguePoints",
        "{{points}} LP",
        {
          points: lp > 0 ? `+${lp}` : lp,
        },
      ],
      bold: true,
      color: lp > 0 ? "var(--turq)" : lp < 0 ? "var(--red)" : "var(--shade2)",
    });
  }

  // Queue
  if (QUEUE_SYMBOL_TO_OBJECT[queue]?.label) {
    titles.push({
      text: QUEUE_SYMBOL_TO_OBJECT[queue].label,
    });
  } else {
    titles.push({
      text: ["common:queue.uknown", "Unknown queue"],
    });
  }

  // Duration
  if (gameDuration) {
    titles.push({
      text: formatDuration(
        gameDuration / 60 > MAX_GAME_TIME_IN_MINUTES
          ? gameDuration
          : gameDuration * 1000,
        "mm:ss",
      ),
    });
  }

  return (
    <MatchTileExpand
      isWin={win}
      isMvp={isMVP}
      image={{
        src: Static.getChampionImage(championId),
        alt: championId,
      }}
      tabDefault={MatchTileTabsEnum.Scoreboard}
      tabsToElement={isSwarm ? {} : TABS}
      tabsLabel={[
        {
          name: ["tft:matchtabs.scoreboard", "Scoreboard"],
          key: MatchTileTabsEnum.Scoreboard,
        },
        {
          name: ["common:navigation.statistics", "Statistics"],
          key: MatchTileTabsEnum.Stats,
        },
      ]}
      tabArgs={{
        gameId: match.gameId,
        region,
        matchId: match.gameId,
        profile,
        localPlayer,
        playerScores: playersRankings,
      }}
      urlWeb={`${globals.location.origin}${matchLinkPrefix}/${match.gameId}`}
      urlCanonical={`${matchLinkPrefix}/${match.gameId}`}
      titles={titles}
      matchDate={gameCreation}
      aside={
        isArena ? (
          <ArenaAugments localPlayer={localPlayer} />
        ) : isSwarm ? null : (
          <TeamsGrid teams={teams} localPlayer={localPlayer} />
        )
      }
    >
      <div className={Contents()}>
        <div className={MatchStats()} data-is-swarm={isSwarm}>
          <div className="stats">
            {!isSwarm && (
              <div>
                <span
                  className="type-subtitle--bold"
                  style={{ color: kdaColor(kda) }}
                >
                  {t("lol:matchTile.kda", "{{kda}} KDA", {
                    kda: kda.toLocaleString(getLocale(), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                  })}
                </span>
                <span className="type-caption">
                  {t(
                    "fps:kdaBreakdown",
                    "{{ kills, number }} / {{ deaths, number }} / {{ assists, number }}",
                    { kills, deaths, assists },
                  )}
                </span>
              </div>
            )}
            {isArena || isARAM ? (
              <div data-tip={t("common:stats.damagePerMin", "Damage / Min.")}>
                <span className="type-subtitle--bold">
                  {t("common:stats.damagePerMinValue", "{{dpm}} DPM", {
                    dpm: dmgPerMin.toLocaleString(getLocale(), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                  })}
                </span>
                <span className="type-caption">
                  {dmgTotal.toLocaleString(getLocale(), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </span>
              </div>
            ) : isSwarm ? (
              <>
                <div>
                  <span
                    className="type-subtitle--bold"
                    style={{ width: "11ch" }}
                  >
                    {csTotal.toLocaleString(getLocale())}
                  </span>
                  <span className="type-caption" style={{ width: "11ch" }}>
                    {t("lol:stat.minionsSlain", "Minions Slain")}
                  </span>
                </div>
                <div>
                  <span className="type-subtitle--bold">
                    {localPlayer.goldEarned.toLocaleString(getLocale())}
                  </span>
                  <span className="type-caption">
                    {t("common:gold", "Gold")}
                  </span>
                </div>
                <div>
                  <span className="type-subtitle--bold">
                    {(totalDamageDealt / durationAsMinutes).toLocaleString(
                      getLocale(),
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      },
                    )}
                  </span>
                  <span className="type-caption">
                    {t("lol:stat.dmgPerMin", "Dmg/Min.")}
                  </span>
                </div>
                <div>
                  <span className="type-subtitle--bold">
                    {localPlayer.champLevel.toLocaleString(getLocale())}
                  </span>
                  <span className="type-caption">
                    {t("common:level", "Level")}
                  </span>
                </div>
              </>
            ) : (
              <div>
                <span className="type-subtitle--bold">
                  {t("lol:matchTile.csPerMin", "{{cs}} CS/Min.", {
                    cs: csPerMin.toLocaleString(getLocale(), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                  })}
                </span>
                <span className="type-caption">
                  {t("lol:matchTile.cs", "{{cs}} CS", {
                    cs: csTotal,
                  })}
                </span>
              </div>
            )}
          </div>
          {!isSwarm && (
            <div className="summoners-runes">
              {[summoner1Id, summoner2Id].map((summonerId) => (
                <img
                  key={summonerId}
                  width="24"
                  height="24"
                  src={Static.getSummonerSpellImageById(summonerId)}
                  loading="lazy"
                />
              ))}
              {runes.map((runeId, i) => (
                <img
                  key={runeId}
                  width="24"
                  height="24"
                  src={Static.getRuneImage(runeId)}
                  className={i === 0 ? "keystone rune" : "rune"}
                  loading="lazy"
                />
              ))}
            </div>
          )}
          <div className="items">
            {inventory.map((itemId, i) => {
              if (!itemId) return <div key={i} className="slot" />;
              return (
                <img
                  key={i}
                  width="24"
                  height="24"
                  src={
                    isSwarm
                      ? Static.getSwarmItemImage(itemId)
                      : Static.getItemImage(itemId)
                  }
                  loading="lazy"
                />
              );
            })}
          </div>
        </div>
        {!isSwarm && (
          <Badges
            truncate
            region={region}
            name={name}
            matchId={matchId}
            size="small"
            amountShown={2}
          />
        )}
      </div>
    </MatchTileExpand>
  );
}
export default MatchTileContainer;

function TeamsGrid({ teams, localPlayer }) {
  if (!teams?.length) return null;

  return (
    <div className={Teams()}>
      {teams.map((team, i) => (
        <div key={i} className="team">
          {team.roster.map((player, j) => {
            return (
              <div
                key={j}
                {...classNames(
                  "player",
                  isSamePlayer(player, localPlayer) && "is-local-player",
                )}
              >
                <ChampionImg id={player.championId} size={20} />
                <span className="player-name type-caption">
                  {player.riotIdGameName}
                </span>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}

function ArenaAugments({ localPlayer }) {
  return (
    <div className={Augments()}>
      <div className="augment">
        <ArenaAugmentImage
          augmentId={localPlayer.playerAugment1}
          width="40"
          height="40"
        />
      </div>
      <div className="augment">
        <ArenaAugmentImage
          augmentId={localPlayer.playerAugment2}
          width="40"
          height="40"
        />
      </div>
      <div className="augment">
        <ArenaAugmentImage
          augmentId={localPlayer.playerAugment3}
          width="40"
          height="40"
        />
      </div>
      <div className="augment">
        <ArenaAugmentImage
          augmentId={localPlayer.playerAugment4}
          width="40"
          height="40"
        />
      </div>
    </div>
  );
}

import { styled } from "goober";

export const ChampionIcon = styled("div")`
  display: flex;
  position: relative;
`;

export const RoleIconWrapper = styled("div")`
  position: absolute;
  bottom: calc(var(--sp-1) * -1);
  right: calc(var(--sp-1) * -1);
  padding: var(--sp-0_5);
  background: var(--shade7);
  border-radius: var(--br);
  transition: background var(--transition);

  svg {
    width: var(--sp-3_5);
    height: var(--sp-3_5);
  }
`;

export const WinRateInfo = styled("div")`
  display: flex;
  flex-direction: row;

  p {
    padding: 0;
    line-height: var(--sp-5);
  }
`;

export const LeaguePoints = styled("div")`
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;

  & > p {
    text-align: right;
  }
`;

import { css, styled } from "goober";

export const PageWrapper = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const AramChampions = () => css`
  & {
    display: flex;
    justify-content: space-between;
    gap: var(--sp-1);
    padding: var(--sp-6);
    padding-block-start: var(--sp-3_5);
  }

  .bar {
    --bg: var(--shade7);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 3.5rem;

    .fill,
    .padd {
      background: var(--shade6-50);
    }
    .fill,
    .wins {
      border-top-left-radius: var(--br-sm);
      border-top-right-radius: var(--br-sm);
    }
    .fill {
      flex: var(--bar-fill);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .wins {
      flex: var(--wr-fill);
      background: var(--shade5);
    }
    .padd {
      height: var(--sp-2);
    }
  }
`;

export const AramChampionTooltip = () => css`
  & {
    display: grid;
    gap: var(--sp-0_5);
  }
  .stat {
    display: flex;
    gap: var(--sp-8);
    align-items: baseline;
    justify-content: space-between;
    padding: var(--sp-1) var(--sp-2);
    border-radius: var(--br-sm);
    background: var(--shade3-25);
  }
`;

import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

import ChampionOverallStats from "@/game-lol/components/ChampionOverallStats.jsx";
import { findPlayer } from "@/game-lol/utils/match-utils.mjs";
import { isRemakeGame, isSwarmQueue } from "@/game-lol/utils/util.mjs";
import { useStickyAttribute } from "@/util/use-sticky.mjs";

const Container = styled(Card, forwardRef)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  z-index: 2;
  border-radius: var(--br-lg);

  top: var(--content-start);
  box-shadow: 0 0 var(--sp-4) var(--shade10-50);
`;

const computeAggregatedMatchStats = ({ matches, currentAccount }) => {
  if (!matches || !currentAccount) return { top3ChampionStats: [] };

  const objectAttributesSumOp = (obj, addendum) =>
    Object.keys(addendum).reduce((res, attr) => {
      res[attr] = (res[attr] || 0) + addendum[attr];
      return res;
    }, obj || {});

  const playerChampions = {};
  const result = matches
    .filter((match) => {
      return (
        findPlayer(currentAccount, match) &&
        !isRemakeGame(match.gameDuration) &&
        !isSwarmQueue(match.queueId)
      );
    })
    .reduce((total, match) => {
      const { championId, win, assists, deaths, kills } = findPlayer(
        currentAccount,
        match,
      );

      const addendum = {
        wins: win ? 1 : 0,
        assists,
        deaths,
        kills,
        plays: 1,
        lp: match.deltaLp,
      };
      playerChampions[championId] = objectAttributesSumOp(
        playerChampions[championId] || { championId },
        addendum,
      );
      return objectAttributesSumOp(total, addendum);
    }, {});

  result.top3ChampionStats = Object.values(playerChampions)
    .sort((a, b) => b.plays - a.plays)
    .slice(0, 3);

  return result;
};

function MatchTileHeader({ role, matches, currentAccount, champions }) {
  const { t } = useTranslation();
  const { lp, plays, wins, top3ChampionStats } = useMemo(
    () =>
      computeAggregatedMatchStats({
        role,
        matches,
        currentAccount,
      }),
    [matches, role, currentAccount],
  );

  const stickyRef = useStickyAttribute();

  return (
    <Container padding="var(--sp-4)" ref={stickyRef}>
      <div className="flex gap-sp-2">
        <div>
          <p className="type-body2-form--active match-info-title">
            {t("lol:lastGames", "Last {{totalGames}}", {
              totalGames: plays ? plays : "",
            })}
          </p>
          <p className="type-caption match-info-content">
            {t(
              "lol:matchHistory.winsAndLossesWithHypen",
              "{{wins}}W-{{losses}}L",
              {
                wins: matches ? (wins ? wins : 0) : " ",
                losses: matches ? (plays - wins ? plays - wins : 0) : " ",
              },
            )}
          </p>
        </div>
        {lp ? (
          <p
            className="type-caption--bold match-info-content"
            style={{
              color:
                lp > 0
                  ? "var(--turq)"
                  : lp < 0
                    ? "var(--red)"
                    : "var(--shade3)",
            }}
          >
            {lp > 0 ? "+" : ""}
            {t("lol:leaguePoints", "{{points}} LP", {
              points: lp !== null ? lp : "-",
            })}
          </p>
        ) : null}
      </div>
      <div className="flex align-center gap-sp-4">
        {top3ChampionStats.map((champStat, index) => (
          <ChampionOverallStats
            key={index}
            data={champStat}
            t={t}
            champions={champions}
          />
        ))}
      </div>
    </Container>
  );
}
export default MatchTileHeader;

import { ROLE_SYMBOL_TO_STR } from "@/game-lol/constants/constants.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";

// Function for computing player champion stats
export function computeChampionStats(stats = []) {
  if (!stats) return [];

  const championStats = stats.reduce((acc, curr) => {
    const champ = curr;
    const roleSymbol = champ.role ? RoleSymbol(champ.role) : RoleSymbol("all");
    const roleStr = ROLE_SYMBOL_TO_STR[roleSymbol]?.gql;
    const key = `${champ.championId}:${roleStr}`;

    if (!acc[key]) acc[key] = { ...champ };
    acc[key].role = ROLE_SYMBOL_TO_STR[roleSymbol].key;

    return acc;
  }, {});

  return Object.values(championStats).sort((a, b) => b.gameCount - a.gameCount);
}
